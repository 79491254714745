@import '~@hai/ui-styles/themes/index-argon.scss';
@import '~bootstrap-less-port';

html,
#root,
.layout-container {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: $haiui-font-family-01;
  background-color: $haiui-gray-01;
  height: 100%;
  overflow: hidden;
  color: $haiui-white;
}

* {
  box-sizing: border-box;
}

img:not([alt]) {
  border: 2px solid red;
}

code,
pre {
  color: $haiui-white;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
a:visited,
a:active {
  cursor: pointer;
  color: $haiui-white;
  text-decoration: none;
}

/*
 *  HAIUI OVERWRITEN STYLES
 */

nav.navbar.HaiSidebar {
  padding: 0;
}

.HaiSidebarContainer .HaiSidebar .HaiSidebarNav {
  padding-bottom: 54px !important;
}

.HaiOverlayPopover.argon {
  background-color: #444 !important;
}

.HaiOverlayPopover.argon.right .arrow::after {
  border-right-color: #444 !important;
}

.HaiSidebar .sidebar-info .info-text {
  text-overflow: ellipsis !important;
}

.HaiSidebar .sidebar-info .info-text .user-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem !important;
}

.HaiExpando .panelHeader {
  display: flex;

  & .panelToggleArea {
    padding-top: 7px !important;
  }
}

/*
 *  UTILS
 */

ol,
ul {
  &.small-padding {
    padding-left: 15px;
    -webkit-padding-start: 15px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// ANSI COLORS

.ansi-bright-black-fg,
.ansi-black-fg {
  color: $haiui-gray-01;
}
.ansi-black-bg {
  background-color: $haiui-gray-01;
}
.ansi-bright-red-fg,
.ansi-red-fg {
  color: $haiui-red-01;
}
.ansi-red-bg {
  background-color: $haiui-red-01;
}
.ansi-bright-green-fg,
.ansi-green-fg {
  color: $haiui-green-01;
}
.ansi-green-bg {
  background-color: $haiui-green-01;
}
.ansi-bright-yellow-fg,
.ansi-yellow-fg {
  color: $haiui-amber-01;
}
.ansi-yellow-bg {
  background-color: $haiui-amber-01;
}
.ansi-bright-blue-fg,
.ansi-blue-fg {
  color: $haiui-blue-01;
}
.ansi-blue-bg {
  background-color: $haiui-blue-01;
}
.ansi-bright-magenta-fg,
.ansi-magenta-fg {
  color: $haiui-purple-01;
}
.ansi-magenta-bg {
  background-color: $haiui-purple-01;
}
.ansi-bright-cyan-fg,
.ansi-cyan-fg {
  color: $haiui-aqua-01;
}
.ansi-cyan-bg {
  background-color: $haiui-aqua-01;
}
.ansi-bright-white-fg,
.ansi-white-fg {
  color: $haiui-white;
}
.ansi-white-bg {
  background-color: $haiui-white;
}

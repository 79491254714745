@import "~@hai/ui-styles/themes/index-argon.scss";

.utcComponent {
  position: absolute;
  // left: 160px;
  // bottom: -5px;
  z-index: 999999;
  box-shadow: $haiui-box-shadow-01;

  bottom: 0;
  left: -5px;

  button {
    background-color: $haiui-gray-01;
    color: $haiui-white;
    border-radius: $haiui-border-radius-01;
    border: 0;
    border-top: 3px solid $haiui-aqua-01;
    font-weight: bold;
    width: 150px;
    padding: 5px 15px 10px;

    transition: width 0.5s ease;
    transition-delay: 0.3s;

    &:focus {
      outline: none;
    }
    &:hover {
      color: $haiui-blue-02;
      svg {
        rect,path{
          fill: $haiui-blue-02;
        }
      }
    }
  }

  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;

    transition: width 0.5s ease;
    transition-delay: 0.3s;
    rect,
    path {
      fill: $haiui-white;
    }
  }
}

:global(.collapse-sidebar) {
  .utcComponent button {
    width: 95px;
    transition-delay: 0s;

    svg {
      width: 0 !important;
      transition: width 0.3s ease;
      transition-delay: 0s;
    }
  }
}

.modal {
  :global(.HaiModal) {
    padding: 10px;
  }
  :global(.modal-title) {
    font-size: 1.1rem !important;
    font-weight: bold;
  }
  p {
    font-size: 1rem;
  }
}

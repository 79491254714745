@import '~@hai/ui-styles/themes/index-argon.scss';

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  & > div > div {
    border-bottom: 0.25rem solid $haiui-gray-01 !important;
  }
}

@import '~@hai/ui-styles/themes/index-argon.scss';

.LoginContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: -5%;

  .Form {
    @media only screen and (max-width: 500px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .ErrorMessage {
      margin-bottom: $haiui-spacing-01;
    }
  }

  .haivisionLogo img {
    width: 8rem;
    height: 8rem;
  }

  .hubLogo {
    display: block;
    margin-top: 2rem;

    span {
      font-size: 2rem;
    }
  }

  .oktaDomain {
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 0;
    transition: height 250ms ease-in-out;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    :global(.HaiTextInputWrapper) {
      width: 200px;
    }
  }
}

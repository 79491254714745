@import '~@hai/ui-styles/themes/index-argon.scss';

.LayoutContainer {
  display: flex;
  height: 100%;

  main {
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    scroll-behavior: smooth;

    .ViewContent {
      padding: 1rem;
      height: 100%;
    }
  }

  select {
    background: transparent !important;
    color: $haiui-white !important;

    option {
      color: $haiui-gray-04 !important;
    }
  }
}
